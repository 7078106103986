@import './colors';

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('../assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
  U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --font-family-plain-text: 'Roboto', sans-serif;
  --font-family-monospace: 'Roboto Mono', monospace;
  --font-size-small: 11px;
  --font-size-sub: 12px;
  --font-size-text: 13px;
  --font-size-medium: 14px;
  --font-size-title: 16px;
  --font-size-menu: 18px;
  --font-size-heading: 20px;
  --font-size-xlarge: 24px;
  --font-weight-light: 300;
  --font-weight-medium: 400;
  --font-weight-title: 500;
  --font-weight-semibold: 600;
}

@mixin plain-text() {
  color: var(--plain-text);
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-light);
}

@mixin body-light() {
  color: var(--plain-text);
  font-size: var(--font-size-sub);
  font-weight: var(--font-weight-light);
}

@mixin headline1() {
  @include title;
}

@mixin headline2() {
  @include subtitle;
  color: var(--app-purple);
  text-transform: uppercase;
  opacity: 0.6;
}

@mixin control-label() {
  color: var(--app-grayish);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semibold);
  text-transform: uppercase;
  opacity: 0.7;
}

@mixin text() {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-light);
}

@mixin text-regular() {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-medium);
}

@mixin text-medium() {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-title);
}

@mixin subtitle() {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
}

@mixin title() {
  font-size: var(--font-size-xlarge);
  font-weight: var(--font-weight-medium);
  color: var(--plain-text);
}

@mixin title2() {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight-medium);
  color: var(--plain-text);
}

@mixin title3() {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-title);
  color: var(--plain-text);
}

@mixin buttonText() {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-semibold);
  color: var(--app-white);
}

@mixin link() {
  text-decoration: underline;
  color: var(--app-purple);

  &:hover {
    cursor: pointer;
  }
}

@mixin small-title-value-upper() {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  color: var(--app-purple);
}
