@import '../../styles/fonts';
@import '../../styles/colors';

.CellRenderSeverity {

  .inner-container {
    display: flex;
    align-items: center;
    height: 32px;
  }

  .ant-select-single:not(.ant-select-customize-input) {

    &:hover {

      .ant-select-arrow {
        opacity: 1;
      }
    }

    .ant-select-arrow {
      opacity: 0;
    }

    .ant-select-selector {
      border: none !important;
      background-color: transparent;
    }

    &.ant-select-open {

      .ant-select-arrow {
        opacity: 1;
      }

      .ant-select-selector {
        border: 1px solid var(--app-purple-shade-two);
        background-color: var(--app-white);
      }
    }
  }
}

.CellRenderToggle {
  min-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-title {
  white-space: nowrap;
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-medium);
  color: var(--plain-text);

  &:hover {
    text-decoration: underline;
  }
}

.scan-status-cell {
  @include text-regular();
  display: flex;
  align-items: center;
  gap: 8px;

  .running {
    animation: spin 3s linear infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
