@import '../../styles/mixins';


.jira-ticket-input {
  position: relative;
  margin-top: -5px;

  .jti-label {
    @include control-label-top;
  }
}
