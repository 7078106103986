.DataSourceDetails-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  settings-section-header {
    margin: 4px 0 20px 0;
  }

  .DataSourceDetails {
    flex-grow: 1;
    height: 0;
    margin-top: 12px;
    background: var(--app-white);
    box-shadow: 0px 4px 30px var(--container-box-shadow);
    border-radius: 3px 3px 0px 0px;

    .top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 24px;
      background-color: var(--app-white);
      box-shadow: 0 2px 20px var(--tabs-box-shadow);
      border-radius: 3px 3px 0 0;

      .UiTabs {
        background: none;
        box-shadow: none;
      }
    }
  }
}
