@import '../../../../../styles/_mixins.scss';

.card-entity-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  .gridCell .cell-content {
    line-break: auto;
    @include line-clamp(2);
  }
}
