@import '../../styles/mixins';


.alert-severity-multi-select {
  position: relative;
  margin-top: -5px;

  .asms-label {
    @include control-label-top
  }
}
