.filter-tag-list {
  display: flex;
  align-items: start;
  flex-wrap: wrap;

  .clear-all {
    color: var(--app-purple);
    padding-left: 0px;
    height: 26px;
  }
}

.condition-title {
  text-transform: capitalize;
}