@import '../../styles/mixins';


.contains-text-predicate-input {
  position: relative;
  margin-top: -5px;

  .ctpi-label {
    @include control-label-top;
  }

  .ctpi-predicate {
    display: flex;
    align-items: center;
  }
}
