@import '../../styles/mixins';

.ExpandablePanel {
  @include expandable-box-shadow;
  position: relative;
  width: 100%;
  margin-block-end: 20px;
  background: var(--app-white);
  transition: all 0.5s;

  &[data-expandable="true"] {
    padding-block-end: 22px;

    &::after {
      @include expandable-box-shadow;
      content: "";
      width: 45px;
      height: 45px;
      background: var(--app-white);
      position: absolute;
      bottom: -21px;
      left: 50%;
      border-radius: 50%;
      transform: translateX(-50%);
    }
  }

  &[data-expandable="false"] {
    padding-block-end: 1px;
  }
}
