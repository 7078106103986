.ScanExtraDetails {
  flex: 1 1;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 50px;

  .data-item {
    width: 25%;
  }

  .value-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
