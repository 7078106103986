.Testing {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--app-window-padding);
  padding-block-start: 32px;

  .testing-content {
    flex: 1;
  }

  .subsections-command-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--app-white);
    box-shadow: 0 2px 20px var(--tabs-box-shadow);
    padding-inline-end: 20px;
    z-index: 1;

    .tabs-nav {
      flex-grow: 1;
      box-shadow: none;

      .ant-tabs-nav {
        padding: 0;
      }
    }
  }
}
