.EndpointFilterForm {
  padding-inline: 24px;

  > .ant-form {

    .endpoint-filter-radios {
      margin-block-end: 20px;

      .ant-form-item-control-input {
        min-height: unset;
      }
    }

    .ant-form-item-label label {
      margin-block-end: 8px
    }

    > .ant-checkbox-wrapper-checked {
      margin-block-end: 10px;
    }
  }
}
