@import '../../ui-ag-grid/customRenderers';
@import '../../../styles/fonts';

.alert-item {
  width: 100%;
  background-color: var(--app-white);

  .alert-item-content {
    padding: 16px 0;
    display: flex;
    overflow: hidden;
    position: relative;
    min-height: 120px;
    border-bottom: 1px solid var(--app-purple-shade-three);
  }

  &.stretch-height {
    height: 100%;
  }

  .left-side {
    overflow: hidden;
    display: flex;
    gap: 10px;
    width: 40%;
    min-width: 220px;
    padding-right: 10px;

    .info-container {
      max-width: 178px;
      
      .data-name {
        width: 178px;
        font-weight: var(--font-weight-title);
        color: var(--plain-text);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  
    .severity-icon {
      min-width: 27px;
    }

    .name {
      font-weight: var(--font-weight-title);
      color: var(--plain-text);
    }

    .date {
      padding: 3px 0 10px;
      color: var(--plain-text);
      font-weight: var(--font-weight-medium);
    }

    .labels-list {
      // component and css borrowed from ag-grid custom renderer
      @include labelsRenderer;
    }
  }

  .right-side {
    flex: 3 1;
    padding-left: 16px;
    border-left: 1px solid var(--app-purple-shade-two);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 60%;
    min-width: 162px;

    .ui-title .small {
      @include control-label;
    }
  
    .data-row {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 100%;
  
      .value {
        padding-left: 5px;
        color: var(--plain-text);
        font-size: var(--font-size-text);
        font-weight: var(--font-weight-medium);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  
  .expand {
    display: flex;
    align-items: center;
  }
  
  .ui-risk-progress-container {
    margin-left: 10px;
  }
}

.data-description {
  font-weight: var(--font-weight-medium);
}