@import './../../styles/fonts';

.list-container {
  height: 100%;
  display: flex;

  .ant-tabs.ant-tabs-top.ant-tabs-card .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
    overflow-y: auto;
  }

  .list {
    height: 100%;
    position: relative;

    .ag-row .ag-cell {
      padding: 0;
    }

    .ag-header-row, .ag-header-viewport, .ag-header {
      min-height: 52px !important;
      border: none;
    }

    .ag-header-cell {
      background-color: var(--lighter-grey);
      min-height: 52px;
      padding: 15px 24px;
      text-transform: uppercase;
      width: 100%;

      &-text {
        color: var(--plain-text);
        @include text-regular;
      }
    }

    .split-view {
      overflow: hidden auto;

      .split-view-view {
        height: auto;
      }
    }

    .header-row, .list-row {
      min-height: 38px;
    }

    .header-row {
      padding: 15px 24px;
      background-color: var(--lighter-grey);
      text-transform: uppercase;
      width: 100%;
      min-height: 52px;

      .group-title {
        color: var(--plain-text);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-text);
      }
    }

    .list-row {
      display: flex;
      padding: 15px 24px;
      border-bottom: 1px solid var(--app-purple-shade-three);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      color: var(--app-grayish);

      &.key {
        font-weight: var(--font-weight-semibold);
      }

      &.value {
        flex: 1
      }
    }
  }
}

.ns-link {
  color: var(--plain-text);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
