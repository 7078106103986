@import '../../styles/mixins';


.label-multi-select {
  position: relative;
  margin-top: -5px;

  .lms-label {
    @include control-label-top
  }
}
