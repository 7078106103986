.ScanDetailsSummary {
  height: 92px;
  position: relative;
  font-weight: normal;
  padding: 12px 32px;
  z-index: 100;

  $iconValueGap: 7px;

  .row {
    justify-content: space-between;

    .header {
      display: flex;
      gap: $iconValueGap;
      margin-bottom: 25px;
      align-items: center;
    }

    .value {
      display: flex;
      align-items: center;
      gap: $iconValueGap;
    }
  }

  .arrow-change-icon {
    display: inline-flex;
  }
}
