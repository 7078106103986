.EntityTimeline {
  position: relative;
  background: var(--settings-bg);
  height: 100%;
  width: 100%;
  box-shadow: 0 4px 30px var(--container-box-shadow);
  display: flex;
  flex-direction: column;

  .timeline-header {
    padding: 35px 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--app-purple-shade-two);
    align-items: center;

    .timeline-header-title {
      color: var(--plain-text);
      font-size: var(--font-size-heading);
      font-weight: var(--font-weight-medium);
    }
  }

  .switch-container {
    display: flex;
    justify-content: flex-end;
    padding: 22px;
  }

  .custom-timeline {
    flex-grow: 1;
    padding-left: 16px;
    overflow: auto;

    .ant-timeline {
      width: 100%;
    }

  }

  .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    .hidden {
      display: none;
    }
  }
}
