@import '../../styles/mixins';


.webhook-input {
  position: relative;
  margin-top: -5px;

  .wi-label {
    @include control-label-top;
  }
}
