@import '../../../../styles/fonts';

.ScanRelatedEndpoints {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px;
    border-left: 1px solid var(--app-purple-shade-three);

    .endpoint-item {
        background-color: var(--comment-bg);
        border-radius: 1px;
        padding: 16px;
        @include text-regular;
        color: var(--plain-text);

        &.no-data {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            span {
                font-size: var(--font-size-title);
                font-weight: var(--font-weight-semibold);
                color: var(--plain-text);
                opacity: 0.3;
                letter-spacing: 0.7px;
                margin-top: 5px;
            }
        }
    }
}
