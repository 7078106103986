.UiChronoRangePicker {

  .ant-picker {
    width: 340px;
    height: 37px;
  }
}

.crp-dropdown .ant-picker-panel-container {
  display: flex;

  .crp-range-options {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 270px;
    width: 128px;

    .range-options-button {
      flex-grow: 1;
      height: initial;
      border: 1px solid;
      border-radius: 0;
      border-color: transparent;
      border-bottom: 1px solid var(--app-purple-shade-one);
      transition: border 0s;

      &[disabled] {
        background-color: var(--app-white);
      }

      &.active {
        border-left: 3px solid var(--app-purple);
        background-color: var(--selected-purple);

        &:hover:not([disabled]) {
          background-color: var(--hover-background);
        }
      }

      &:hover:not([disabled]) {
        color: var(--app-purple);
        background-color: var(--btn-secondary-hover);
        z-index: 1;
      }
    }
  }

  .crp-controls {
    border-left: 1px solid var(--app-purple-shade-one);

    .ant-picker-panel {
      &,
      .ant-picker-header {
        border-bottom: 1px solid var(--app-purple-shade-one);
      }
    }

    .crp-footer-container {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      .crp-buttons {
        display: flex;
        gap: 10px;
      }
    }
  }
}
