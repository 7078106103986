@import '../../../styles/mixins';

.ScanDetails {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 16px;
  }

  .scan-summary-title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-xlarge);
    line-height: var(--font-size-xlarge);
    color: var(--plain-text);
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  .scan-details-expandable {
    margin-block: 20px;

    .expanded-section {
      display: flex;
      height: 0;
      background-color: var(--app-white);
      border-top: 1px solid transparent;
      transition: all 0.7s, border 0.3s 0.2s;
      overflow: hidden;

      &.expanded {
        @include expandable-transition;
        height: 247px;
        border-top: 1px solid var(--app-purple-shade-three);
      }

      .heading-icon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .finding-types-table {
    flex: 1;
  }
}

.UiDropdownOverlay.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .export-dropdown-action-item {

  svg {
    height: unset;
    width: unset;
  }
}

.dropdown-container {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--app-purple);
  color: white;
  padding: 0px 16px;
  width: 100px;
  height: 36px;
  border-radius: 3px;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-title);
  cursor: pointer;
}
