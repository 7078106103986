@import "../../styles/mixins";

.user-summary-container {
  height: 72px;
  z-index: 100;
  position: relative;
  color: var(--app-purple);
  font-weight: normal;
  align-items: center;
  padding: 20px 24px;
  display: flex;

  .ui-title .medium {
    color: var(--app-white);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-title);
  }

  .info-item {
    flex: 1;

    .value {
      font-size: var(--font-size-title);
    }

    &:not(:first-of-type) {
      padding-left: 30px;

      .value {
        font-size: var(--font-size-medium);
      }
    }

    // columns width

    &.five-cols {
      &.info-item-id {
        max-width: 23%
      }

      &.info-item-alerts {
        max-width: 15%
      }

      &.info-item-location {
        max-width: 30%
      }

      &.info-item-first-seen {
        max-width: 16%
      }

      &.info-item-last-seen {
        max-width: 16%
      }
    }

    &.six-cols {
      &.info-item-id {
        max-width: 16%
      }

      &.info-item-alerts {
        max-width: 9%
      }

      &.info-item-location, &.info-item-relates-entities {
        max-width: 25%
      }

      &.info-item-user-agent {
        max-width: 25%
      }

      &.info-item-first-seen {
        max-width: 12%
      }

      &.info-item-last-seen {
        max-width: 12%
      }
    }


    .value {
      display: flex;
      align-items: center;
      max-width: 100%;
      width: 100%;
      font-size: var(--font-size-title);
      white-space: nowrap;
      min-width: 100px;
      color: var(--app-white);

      svg {
        path {
          stroke: var(--app-white) !important;
        }
      }

      .text {
        overflow: hidden;
        font-size: inherit;
        text-overflow: ellipsis;
        line-height: 23px;
        color: var(--app-white);
      }


      // individual icon alignments

      &.location-value, &.alerts-value {

        > .anticon {
          margin-right: 2px;
        }
      }

      &.user-agent-value {

        > .anticon {
          margin-right: 6px;
        }
      }

      &.first-seen-value, &.last-seen-value {
        > .anticon {
          margin-right: 6px;
        }
      }
    }
  }
}
