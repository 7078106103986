@import './colors';
@import './fonts';
@import './dimensions';


@mixin line-clamp($noOfLines) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $noOfLines;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

@mixin control-label-top() {
  @include control-label;
  margin-bottom: 8px;
}

@mixin control-label-inline() {
  @include control-label;
  padding-right: 10px;
}


@mixin long-text-ellipsis($maxWidth) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $maxWidth;
  display: inline-block;
  vertical-align: middle;
}

@mixin card-list-grid($noOfCols) {
  display: grid;
  grid-template-columns: repeat($noOfCols, minmax(0, 1fr));
  column-gap: 27px;
  row-gap: 24px;
}

@mixin card-list-page($noOfCols) {
  height: 100%;
  display: flex;
  flex-direction: column;

  .subtitle {
    font-size: var(--font-size-title);
    height: var(--subtitle-height);
    margin: var(--subtitle-margins);
  }

  .content {
    flex-grow: 1;

    .card-grid {
      @include card-list-grid($noOfCols);
      padding-bottom: 18px;
    }
  }
}

@mixin expandable-box-shadow {
  box-shadow: 0 2px 20px var(--expandable-box-shadow);
}

@mixin expandable-transition {
  transition: all 0.7s;
}
