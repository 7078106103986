@import '../../styles/mixins';
@import '../../styles/fonts';

@mixin labelsRenderer {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .line {
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    gap: 3px 6px;

    & > span {
      overflow-y: hidden;
      text-overflow: ellipsis;
    }
  }

  .line-2 {
    margin-top: 3px;
  }
}

.ag-tabs.ag-menu.ag-focus-managed.ag-popup-child {
  overflow: visible;
}

.ag-row.ag-row-group-expanded {
  background-color: rgb(240, 241, 243) !important;
  box-shadow: 0px 4px 12px rgba(50, 78, 149, 0.07);
  border-left: 3px solid var(--app-purple);
}

.ag-row-group-expanded {
  .custom-cell-renderer {
    &.cell-expand-button {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.custom-cell-renderer {
  width: 100%;

  &.table-row-detail-panel {
    display: flex;
    height: 100%;
    padding: 12px 0;
    overflow: hidden;
    background-color: var(--secondary-bg-color);

    .section {
      flex: 1;
      display: flex;
      flex-direction: column;
      white-space: pre-wrap;
      overflow-x: auto;
      padding-inline-start: 25px;

      &.hide-section,
      .hide-content {
        display: none;
      }

      &:not(:last-child) {
        padding-right: 25px;
      }

      .section-content {
        min-height: calc(100% - 32px);
        overflow-y: auto;
      }

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
      }

      &.recommendations, &.comments {

        .section-header {

          .add-comment-button {
            display: flex;
            align-items: center;
            height: 30px;
            gap: 5px;
            cursor: pointer;

            &.disabled-btn {
              &:hover {
                cursor: not-allowed;
              }

              pointer-events: none;
            }

            .add-comment-btn {

              span {
                text-decoration: none;
                color: var(--risk-info-color);
              }
            }
          }
        }
      }
    }

    .desc {
      flex: 1;
      border-right: 1px solid var(--divider);
    }

    .more-info,
    .recommendations {
      border-right: 1px solid var(--divider);
    }

    .comments {
      padding-right: 0;
      position: relative;
      overflow-y: hidden;
      display: block;

      .section-content {
        min-height: calc(100% - 38px) !important;
      }

      .Spinner {
        position: absolute;
        left: 0;
        top: 30px;
      }
    }
  }

  .ep-change-cell-content-wrapper {

    .value {
      margin-inline-end: 8px;
    }

    .anticon {
      vertical-align: 0;
    }
  }

  &.table-more-button {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &.labels-renderer {
    // component and css shared with alert-item component
    @include labelsRenderer;
  }

  &.description-renderer {
    @include line-clamp(3);
  }

  &.cell-expand-button {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      transition: transform 0.4s ease-out;
    }
  }

  &.vertical-center {
    white-space: normal;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
  }

  &.vertical-center,
  &.pre-wrap {
    font-weight: var(--font-weight-medium);
  }

  &.endpoint-renderer .tag-element {
    text-align: center;
    width: 35px;
  }
}

.ag-react-container {

  .custom-filter-date {
    position: relative;
    overflow: visible;

    .options-container {
      padding: 12px;

      .range-option {
        height: 36px;
        color: var(--plain-text);
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;

        &.any {
          border-bottom: 1px solid var(--app-purple-shade-one);
        }

        &:hover {
          background-color: var(--hover-background);
        }

        &.active {
          background-color: var(--selected-purple);
        }
      }

      .ui-date-picker-container {
        padding: 0 12px 12px 12px;
        width: 370px;
        background-color: var(--grey-background);

        input {
          font-size: var(--font-size-text);
        }
      }
    }

    .footer {
      padding: 12px;
      border-top: 1px solid #dde2eb;
      display: flex;
      justify-content: space-between;
    }
  }
}

.header-cell-container {
  display: flex;
  width: 100%;

  &:hover {
    .header-button {
      opacity: 1;
    }
  }

  .header-button {
    opacity: 0;
    transition: opacity 0.2s;
  }

  .customHeaderLabel {
    display: flex;
    align-items: center;
    gap: 8px;

    .customHeaderLabel-content {
      @include control-label;
    }
  }

  .spacer {
    flex: 1;
  }

  .customFilterButton {
    margin-left: 4px;
    color: #dcdcdc;

    &.active {
      color: var(--app-purple);
    }
  }

  .active {
    opacity: 1;
  }
}

.ag-row {
  .hover-icon {
    opacity: 0;
    -webkit-transition: opacity 0.1s ease;
    transition: opacity 0.1s ease;
  }

  &.ag-row-hover {
    .hover-icon {
      opacity: 1;
      cursor: pointer;
    }
  }

  .table-row-detail-panel {
    display: flex;
    height: 100%;
    padding: 12px 24px;
    overflow: hidden;
    background-color: var(--secondary-bg-color);

    .section {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 22px;
      white-space: pre-wrap;
      overflow-x: auto;

      .section-content {
        min-height: 0;
        overflow-y: auto;
        margin-block: 8px;
      }
    }

    .desc {
      flex: 1;
      border-right: 1px solid var(--divider);
      padding: 0;
    }

    .section:not(:last-child) {
      border-right: 1px solid var(--divider);
    }

    .section:last-child {
      border-right: none;
    }
  }
}

.ant-tooltip-content {
  .tooltip-wrapper {
    font-weight: var(--font-weight-title);

    .ep-change-list-tooltip {
      margin: 0;
      padding-left: 15px;
    }
  }
}

.status-container {
  display: flex;
  align-items: center;
  min-width: 90px;
  gap: 5px;
}

.status-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &.status-dot-Open {
    background-color: var(--red);
  }

  &.status-dot-Closed {
    background-color: var(--closed);
  }
}

.CellRenderLink {
  color: var(--plain-text);

  &:hover {
    color: var(--plain-text);
  }
}
