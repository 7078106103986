@import '../../styles/mixins';

.alert-severity-select {
  position: relative;
  margin-top: -5px;

  .ass-label {
    @include control-label-top;
  }
}
