.ConditionCellRenderer {
  display: flex;

  .ccr-operator {
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-title);
    color: var(--plain-text);
    min-width: 75px;
  }

  .ccr-filter-name {
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-medium);
    color: var(--info-blue);
    margin-right: 10px;
  }

  .ccr-value {
    font-family: var(--font-family-monospace);
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-light);
    color: var(--plain-text);
    line-height: 18px;
  }

  .ccr-method {
    margin-left: 10px;
  }

  &--disabled {
    .ccr-operator,
    .ccr-filter-name,
    .ccr-value,
    .ccr-method {
      color: var(--disabled-text);
    }
  }
}
