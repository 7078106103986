:root {
  --app-background: #edf0f7;
  --app-gray-background: #f2f2f2;
  --app-secondary-background: #f8f8fc77;
  --app-dark-background: #2b3755;
  --app-navbar-gradient: linear-gradient(90deg, #0E3762 0%, #27496D 100%);
  --app-white: #ffffff;
  --app-grayish: #606999;
  --app-purple: #436ace;
  --app-purple-shade-one: #436ace33;
  --app-purple-shade-two: #436ace4d;
  --app-purple-shade-three: #436ace26;
  --app-purple-shade-four: #436ace1a;
  --btn-primary-hover: #3b5eb8;
  --btn-primary-focus: #2c4ca2;
  --plain-text: #565b75;
  --text-dark-theme: #a4b2c3;
  --tag-color: #6988d8;
  --disabled-text: #c7c7d4;
  --button-disabled: #a1b5e7;
  --divider: #c8d2f0;
  --light-grey: #f1f1f1;
  --lighter-grey: #f8f8f8;
  --hover-background: #f5f7fc;
  --hover-border: #bbcffa;
  --comment-border: #b9c6ea;
  --comment-bg: #ececec;
  --grey-background: #ecf0fa;
  --grey-background-share-two: #ecf0fab3;
  --silver: #C5C6C9;
  --yellow: #ffa52b;
  --red: #f66060;
  --closed: #aeafb1;
  --critical-red: #e22828;
  --info-blue: #17b0e0;
  --bright-greenish-1: #49cc90;
  --border: lightslategrey;
  --list-background: #f6f8fb;
  --switch-background: #d6dce9;
  --tabs-box-shadow: #e7ebf5;
  --expandable-box-shadow: #00000026;
  --stopLight-gray: #a4b2c4;
  --stopLight-green: #438e22;
  --stopLight-yellow: #ecc922;
  --stopLight-red: #d00808;
  --risk-info-color: #879fdf;
  --risk-low-color: var(--bright-greenish-1);
  --risk-medium-color: #ffa52b;
  --risk-high-color: #f66060;
  --risk-critical-color: #e22828;
  --secondary-bg-color: #f9f9fa;
  --counter-value-color: #d0d2df;

  --selected-purple: #436ace1a;
  --selected-light-purple: rgba(236, 240, 250, 0.4);
  --selected-med-purple: rgb(182, 186, 204);
  --container-box-shadow: rgba(117, 130, 166, 0.14);
  --settings-bg: #ebeef6;
  --bg-grad-1: linear-gradient(180deg, rgba(224, 226, 255, 0.08) 0, rgba(140, 172, 253, 0.08) 95%);
  --bright-blue: #7b97dd;

  --tenant-list-background: #0E3762;
  --tenant-list-hover-background: #edf0f766;
  --tenant-list-border: #436ACE;
}
