@import '../../../styles/fonts';

.FindingTypeDetails {
  display: flex;
  flex-direction: column;
  height: 100%;

  .page-header {
    padding-block: 16px;
    margin-bottom: 20px;

    .page-subheader {
      display: flex;
      align-items: center;

      .ant-divider-vertical {
        border-color: var(--silver);
      }

      .labels-list {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }

  .expandable-content {
    @include text-regular;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 2px;
    background: var(--app-white);
    overflow: hidden;
    z-index: 1;

    &-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: calc(100% - 16px);
      padding: 24px;

      &:not(:last-child) {
        border-right: 0.5px solid var(--app-purple-shade-three);
      }

      &.expanded {
        overflow-y: auto;
      }
    }

    .ftd-references {
      a {
        @include link;
      }
    }
  }

  .findings-table {
    flex: 1;
  }
}

.scan-details-tooltip {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .scan-details-title {
    @include title3;
  }

  .scan-details-content {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows:1fr 1fr;
    grid-template-columns: 40px 100px 150px 140px 140px;
    column-gap: 10px;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
