@import '../../styles/mixins';


.alert-category-multi-select {
  position: relative;
  margin-top: -5px;

  .acms-label {
    @include control-label-top
  }
}
