@import '../../styles/mixins';


.service-multi-select {
  position: relative;
  margin-top: -5px;

  .sms-label {
    @include control-label-top;
  }
}
