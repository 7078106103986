@import '../../../../../styles/_mixins.scss';

.EndpointFilterList {
  display: flex;
  flex-direction: column;
  height: 100%;

  .endpoint-filter-list-table {
    height: 100%;
    overflow: hidden;

    .title-row {
      height: 45px;

      .box-title {
        padding-bottom: 6px;
        display: inline-block;
      }
    }
  }
}

.endpoint-filter-modal {
  .FormModal .ant-modal-content {
    .ant-modal-body .form-modal-body {
      box-shadow: none;
      padding: 0;
      background: transparent;

      .endpoint-filter-form-container {
        background: var(--app-white);
        margin-top: 24px;
        box-shadow: 0 4px 30px var(--container-box-shadow);

        .hidden {
          display: none;
        }

        .endpoint-filter-form-body {
          padding: 16px 24px 21px 24px;

          .endpoint-filter-modal-title {
            margin: 5px 0 18px 0;
            opacity: 0.8;
          }
        }

        .endpoint-filter-preview {
          padding: 16px 24px 10px 24px;

          .endpoint-filter-warning {
            @include headline1();
            display: flex;
            align-items: center;
            font-weight: var(--font-weight-medium);

            .warning-message {
              font-size: var(--font-size-title);
              color: var(--app-purple);
              padding-left: 5px;
            }
          }

          .display-count {
            margin-top: 16px;
            font-family: var(--font-family-plain-text);
            font-size: var(--font-size-text);
            font-weight: var(--font-weight-medium);
            color: var(--plain-text);
          }
        }

        .filtered-endpoint-grid {
          height: 250px;
          overflow-y: hidden;
          box-shadow: 0 2px 20px var(--tabs-box-shadow);

          .ag-theme-alpine .ag-cell:nth-child(2) {
            padding-left: 3px;
          }

          .api-tag-container .tag-element {
            flex-basis: 62px;
          }
        }

        .ant-radio-wrapper {
          min-width: 72px;
        }
      }
    }

    .ant-modal-footer .form-footer button {
      margin: 0;
    }
  }
}
