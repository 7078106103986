

.home-container {
  height: 100%;
  overflow: hidden;
  padding: var(--app-window-padding);
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "alert-overview top-alerting-users recent-alerts"
                        "top-endpoints top-endpoints recent-alerts";

  .alert-overview {
    grid-area: alert-overview;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top-alerting-users {
    grid-area: top-alerting-users;
    overflow: hidden;
    position: relative;
    .user-name{
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }

  .top-endpoints {
    grid-area: top-endpoints;
    overflow: hidden;
    position: relative;
  }

  .recent-alerts-container {
    grid-area: recent-alerts;
    overflow: hidden;
    position: relative;
  }

  .custom-icon-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
